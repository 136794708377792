$plyr-color-main: #5e9499;
$plyr-video-controls-bg: #231f20;
$plyr-font-family: 'GT America Expanded', sans-serif;
@import '~plyr/src/sass/plyr.scss';

// π ----
// :: PLYR STYLES ---------------------------::
// ____

.plyr--video {
    @apply bg-transparent;

    /* :: Default Center Button
    {+} ---------------------------------- */
    .plyr__control--overlaid {
        @apply bg-white shadow-none p-5 transform -translate-x-1/2 -translate-y-1/2;
        color: $plyr-color-main;

        @screen md {
            @apply p-8;
        }

        svg {
            @apply transition-transform duration-300 ease-out-expo transform;
        }

        &:hover,
        &:focus {
            @apply bg-white shadow-none scale-110;
            color: $plyr-color-main;

            svg {
                @apply scale-125;
            }
        }
    }
}

// π ----
// :: CONTROLS ---------------------------::
// ____

/* :: Play/Pause
{+} ---------------------------------- */
.plyr__controls .plyr__controls__item:first-child {
    @apply flex justify-center items-center w-10 h-10 border-solid border border-transparent rounded-full ml-1 mr-2;

    &:hover {
        @apply bg-transparent border-transparent;
        color: $plyr-color-main;
    }

    &.plyr__tab-focus {
        @apply bg-transparent shadow-none border-white;
    }

    .icon--not-pressed {
        margin-left: 2px;
    }
}

/* :: Progress
{+} ---------------------------------- */
.plyr--full-ui input[type='range'] {
    @apply h-3;

    // :: Track ------------ //
    @mixin track {
        height: 1px;
    }

    &::-webkit-slider-runnable-track {
        @include track;
    }

    &::-moz-range-track {
        @include track;
    }

    // :: Scrubber ------------ //
    @mixin scrubber {
        margin-top: -6px;
    }

    &::-webkit-slider-thumb {
        @include scrubber;
    }

    &::-moz-range-thumb {
        @include scrubber;
    }
}

// :: Buffer ------------ //
.plyr__progress__buffer {
    margin-top: -1px;
    height: 1px;
}
